/*
 * Copyright 2023 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "../../global/common";

// fillable_section
$fillable-section-margin: 30px;
$fillable-section-width-min: 1000px;
$fillable-section-width-max: 1200px;

// task_terminal
$task-editor-bg: #333640;
$task-bg: rgba(255, 255, 255, 5%);
$task-current-bg: rgba(255, 255, 255, 10%);
$task-current-prompt: #aadadb;
$task-prompt: #999;
$task-cmd-color: #aadbaa;
$task-mod-color: #dbdbaa;
$task-mod-bg: rgba(128, 128, 255, 40%);
$task-err-bg: rgba(255, 128, 128, 20%);

;@import "sass-embedded-legacy-load-done:5068";